// console.log('Hello!');

$(document).ready(() => {
  $('.carousel').carousel({
    interval: 5000
  })
});

$(".deal-record__item").click(function () {
  $(".deal-record__detail").toggle("slow", function () {
    // Animation complete.
  });
});
window.popupBottom = () => {
  var yourUl = document.getElementById('popupBottom');
  yourUl.classList.toggle('popup-wrapper--show');
};

window.recordList = () => {
  var yourUl = document.getElementById('recordList');
  yourUl.classList.toggle('quotation-record--show');
};

